<template>
  <page-header-wrapper>
    <a-card :bordered="false" class="task_card">
      <a-row>
        <a-col :sm="12" :xs="24">
          <info title="总任务" :value="`${allCount}个任务`" :bordered="true" />
        </a-col>
        <a-col :sm="12" :xs="24">
          <info title="进行中任务" :value="`${runningCount}个任务`" :bordered="true" />
        </a-col>
      </a-row>
    </a-card>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="findByKeyword">查询</a-button>
                <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-modal
        title="刪除"
        :visible="del.visible"
        :confirm-loading="del.confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleDeleteCancel"
      >
        <p>{{ del.ModalText }}</p>
      </a-modal>
      <my-alert :totalCount="totalCount" />
      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="tasksData"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="salNum" slot-scope="text, record">
          <a-progress
            :stroke-color="{
              from: '#108ee9',
              to: '#87d068'
            }"
            :percent="text / record.total"
            status="active"
          />
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看</a>
            <a-divider type="vertical" />
            <a-tooltip placement="top">
              <template slot="title">
                <span>
                  老带新任务二维码
                </span>
              </template>
              <a type="primary" @click="qrCode(record)">二维码</a>
            </a-tooltip>
          </template>
        </span>
      </s-table>

      <create-form
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleCancel"
        @ok="handleOk"
      />
      <step-by-step-modal ref="modal" @ok="handleOk" />
    </a-card>
    <code-create ref="CodeCreate"></code-create>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import Info from './components/Info'
import { STable, Ellipsis, MyAlert } from '@/components'
import { queryTasks, delTasks } from '@/api/tasks'
import { statisticsTask } from '@/api/statistics'
import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreateForm'
import CodeCreate from './modules/CodeCreate'

const columns = [
  {
    title: '任务名称',
    dataIndex: 'name'
  },
  {
    title: '楼盘名称',
    dataIndex: 'house.name'
  },
  {
    title: '任务编号',
    dataIndex: 'id'
  },
  {
    title: '进度',
    dataIndex: 'salNum',
    scopedSlots: { customRender: 'salNum' }
  },
  {
    title: '有效期',
    dataIndex: 'validDate',
    sorter: true
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '未审核'
  },
  1: {
    status: 'processing',
    text: '审核通过'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    CodeCreate,
    StepByStepModal,
    Info,
    MyAlert
  },
  data() {
    this.columns = columns
    return {
      noticeTitle: '任务管理',
      totalCount: 0,
      // statictisc
      runningCount: 0,
      allCount: 0,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 批量删除 modal
      del: {
        ModalText: '您要删除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      // 查询参数
      queryParam: {
        keyword: ''
      },
      search: {
        visible: false
      },
      // 加载数据方法 必须为 Promise 对象
      tasksData: parameter => {
        console.log(parameter, 'parameter')
        let sorterStr = ''
        if (parameter.sortOrder === 'descend') {
          sorterStr = `${parameter.sortField},desc`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
        } else {
          sorterStr = `createTime,desc`
        }
        const requestParameters = {
          status: 1,
          developerId: this.userId,
          page: parameter.pageNo - 1,
          size: parameter.pageSize,
          sort: sorterStr
        }
        if (this.search.visible) {
          requestParameters.keyword = this.queryParam.keyword
        }
        console.log(requestParameters, 'requestParameters')
        return queryTasks(requestParameters).then(res => {
          const result = {
            data: res.data.content,
            pageNo: res.data.number,
            pageSize: res.data.size,
            totalCount: res.data.totalElements,
            totalPages: res.data.totalPages
          }
          result.data.forEach(item => {
            item.validDate = `${item.startTime} 到 ${item.endTime}`
          })
          console.log(result, 'result')
          this.totalCount = result.totalCount
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  created() {},
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    userId() {
      return this.$store.state.user.info.id
    },
    houseId() {
      return this.$store.state.house.houseId
    }
  },
  mounted() {
    this.getTaskStatictisc()
  },
  methods: {
    qrCode(record) {
      this.$refs.CodeCreate.showModal(record)
    },
    getTaskStatictisc() {
      const param = {
        developerId: this.userId
      }
      statisticsTask(param).then(res => {
        console.log(res, 'res')
        this.allCount = this.priceFormat(res.data.allCount)
        this.runningCount = this.priceFormat(res.data.runningCount)
      })
    },
    handleAdd() {
      this.$router.push({
        path: '/task/releaseForm'
      })
    },
    // 批量删除
    handleDelete() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.del.visible = true
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这些项目吗'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          this.selectedRows.forEach((item, index) => {
            console.log(index)
            const requestParameters = {
              id: item.id
            }
            delTasks(requestParameters)
              .then(res => {
                // 刷新表格
                this.$refs.table.refresh()
                this.$message.success('删除成功', 2)
                this.del.visible = false
                this.confirmLoading = false
                this.selectedRows.splice(0, this.selectedRows.length)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    handleDeleteCancel(e) {
      this.del.visible = false
    },
    handleEdit(record) {
      // this.visible = true
      // this.mdl = { ...record }
      const id = record.id
      this.$router.push({
        path: '/task/taskInfo',
        query: {
          id
        }
      })
    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          if (values.id > 0) {
            // 修改 e.g.
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 1000)
            }).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

              this.$message.info('修改成功')
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 1000)
            }).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

              this.$message.info('新增成功')
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    findByKeyword() {
      this.search.visible = true
      this.$refs.table.refresh()
    },
    tableReset() {
      this.search.visible = false
      this.queryParam = {
        keyword: ''
      }
      this.$refs.table.refresh()
    },
    nortifaction(message = '提醒信息', description = '请注意。', type = 'success') {
      this.$notification[type]({
        message,
        description
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
.task_card /deep/ .ant-card-body {
  margin-bottom: 25px;
}
</style>
