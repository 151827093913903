import request from '@/utils/request'

const tasksApi = {
  // get tasks info
  tasks: '/api/v1/rest/tasks',
  findByKeyword: '/api/v1/rest/tasks/search/findByKeyword',
  findByStatus: '/api/v1/rest/tasks/search/findByStatus',
  statistics: '/api/v1/task/statistics',
  query: '/api/v1/task/query',
  appointPublish: '/api/v1/task/appointPublish',
  findByTaskIdTaskBroker: '/api/v1/rest/taskBrokers/search/findByTaskId'
}

/**
 * list
 * @param param { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function tasks (param) {
  let url = tasksApi.tasks
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * list
 * @param param { keyword: '', residentId: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function queryTasks (param) {
  let url = tasksApi.query
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * add
 * @param param { name: '', developer: '', area: '', type: '' , time }
 * @returns {*}
 */
export function addTasks (param) {
  return request({
    url: tasksApi.tasks,
    method: 'post',
    data: param
  })
}

/**
 * edit
 * @param param { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}`,
    method: 'patch',
    data: param
  })
}

/**
 * del
 * @param param { id: '' }
 * @returns {*}
 */
export function delTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}`,
    method: 'delete'
  })
}

/**
 * 
 * @param param id: '', data: '
 * @returns {*}
 */
export function putTaskBroker (id, data) {
  return request({
    url: tasksApi.tasks + `/${id}` + '/brokerRecords',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * 
 * @param param { brokerIds: '', taskId: '' }
 * @returns {*}
 */
export function appointPublish (param) {
  let url = tasksApi.appointPublish
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * find
 * @param param { status: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findTasksByStatus (param) {
  let url = tasksApi.findByStatus
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param param { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findTasks (param) {
  let url = tasksApi.findByKeyword
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param param { id: '' }
 * @returns {*}
 */
export function findByIdTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}`,
    method: 'get'
  })
}

/**
 * tasks
 * @param param { id: '', name: '' }
 * @returns {*}
 */
export function inTasks (param) {
  return request({
    url: tasksApi.tasks + `/${param.id}/${param.name}`,
    method: 'get'
  })
}

/**
 * tasks
 * @param param { id: '', name: '' }
 * @returns {*}
 */
export function statisticsTask (param) {
  let url = tasksApi.statistics
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}

export function brokerRecords(id) {
  return request({
    url: tasksApi.tasks + `/${id}` + '/brokerRecords',
    method: 'get'
  })
}

export function findByTaskIdTaskBroker(param) {
  let url = tasksApi.findByTaskIdTaskBroker
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}
